import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'audamatic-ui';

import './styles/styles.scss';

class Popup extends Component {
  render() {
    const { toggled, title, subtitle, hidebuttons = false, component, content, classNames, closeModal } = this.props;
    return (
      <div className={`modal__popup ${toggled ? 'modal__popup--active' : ''}`}>
        {!!toggled && (<div id="overlay" onClick={closeModal} />)}
        <div className={`modal-container ${toggled ? 'show' : ''} ${classNames}`}>
          <div className="modal" data-test-id="modalPopup">
            <div className="modal-inner">
              <div className={`modal-header ${title ? 'has-header' : 'no-header'}`}>
                {title && <h2>{title}</h2>}
                {subtitle && <p className="modal-subtitle">{subtitle}</p>}
                {hidebuttons === false && (
                  <div className="modal-close" onClick={closeModal}>
                    <Icon size="md" name="Xmark" />
                  </div>
                )}
              </div>
              <div className="modal-body">
                {component}
                {content}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}


Popup.propTypes = {
  /**
   * Boolean if the popup is shown or not
   */
  toggled: PropTypes.bool,
  /**
   * Give the location where the popup needs to be rendered,
   */
  location: PropTypes.string,
  /**
   * Title that needs to be rendered inside the popup
   */
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  /**
   * Hide the buttons that needs to be rendered inside the popup
   */
  hidebuttons: PropTypes.bool,
  /**
   * Extra classnames can be given as props in the openModal function
   */
  classNames: PropTypes.string,
  /**
   * Component that needs to be rendered inside the popup
   * e.g. <Dummy title='Test title' />
   */
  component: PropTypes.object,
  content: PropTypes.string,
  closeModal: PropTypes.func,
  subtitle: PropTypes.string,
};


export default Popup;
