import React from 'react';
import Link from '../../../base_link/component';
import Text from '@text';
import { HEADER } from '@config';
import { useAuth } from '@utils/authentication/AuthProvider';
import { useApp } from '../../../../ssr/shared/application-context';

import './styles.scss';

const AccountMenu = () => {
  const appCtx = useApp();
  const auth = useAuth();
  const { accountUrl = '', accountMenu = [] } = HEADER;

  // Update the app context
  const handleLogout = () => {
    appCtx.setWishlist([]);
    const isAccountPage = window.location.pathname.startsWith('/mijn-account');
    const route = isAccountPage ? '/' : null;
    auth.logout({ reload: true, route });
  };

  return (
    <div className="account-menu">
      {accountMenu.map((item, index) => (
        <Link key={index} to={`${accountUrl}/${item.id}`} rel="nofollow" className={item.hidden ? 'hidden' : ''} data-test-id={`menuItem-${item.id}`}>
          <Text>{item.label}</Text>
        </Link>
      ))}
      <a onClick={handleLogout} data-test-id="headerLogoutLink">
        <Text>account.logout.link</Text>
      </a>
    </div>
  );
};

export default AccountMenu;
