import React, { useState, createContext, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { USER_STORAGE, TOKEN_STORAGE } from '@constants';
import { appStorage } from '@utils';
import { useApp } from '../../ssr/shared/application-context.js';

const AuthContext = createContext(null);

const AuthProvider = ({ children }) => {
  const authCtx = useApp();
  const history = useHistory();
  const { setPreferredStoreId } = authCtx;
  const storedUser = appStorage.getItem('user');
  const initialUserData = storedUser ? JSON.parse(storedUser) : null;
  const [user, setUser] = useState(initialUserData);

  const login = (user) => {
    const { customerNo, firstName, secondName, lastName, address, usergroups, preferredStoreId } = user;
    /*
     * For b2b we need to store the usergroups on authentication
     * Based on these usergroups we either show the order button or additional info with feedback why someone cannot buy this product
     */
    const publicUserData = { customerNo, firstName, secondName, lastName, address, usergroups, preferredStoreId };
    setUser(publicUserData);
    /*
     * To make sure the preferred store id is saved after a login without necessary refresh of pages
     * we are updating its state here in the app context.
     */
    setPreferredStoreId(preferredStoreId);
    appStorage.setItem(USER_STORAGE, JSON.stringify(publicUserData));
  };

  const logout = async ({ reload = false, route = null } = {}) => {
    if (route) {
      await Promise.resolve(history.push(route));
    }

    setUser(null);
    appStorage.removeItem(USER_STORAGE);
    appStorage.removeItem(TOKEN_STORAGE);
    sessionStorage.clear();
    sessionStorage.setItem('loggedout', 'true');

    const path = route || decodeURIComponent(window.location.pathname);
    sessionStorage.setItem('logoutPath', path);

    if (reload) {
      window.location.reload();
    }
  };

  return <AuthContext.Provider value={{ user, login, logout }}>{children}</AuthContext.Provider>;
};

AuthProvider.propTypes = {
  children: PropTypes.node,
};

export default AuthProvider;

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within a AuthProvider');
  }
  return context;
};
