import React, { useEffect, useRef, useState } from 'react';
import { getFeature, getDevice } from '@utils';
import { Button, Icon } from 'audamatic-ui';
import Space from '@components/ui_space/component';
import GenericForm from '@components/sec_generic_form/component';
import Text from '@text';
import { t } from '@utils/i18n';
import PropTypes from 'prop-types';
import './styles.scss';

const ChatWidget = ({store}) => {
  const { deploymentKey, environment, isActive } = getFeature('chat');
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [isChatAvailable, setIsChatAvailable] = useState(false);
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    question: '',
    url: '',
  });
  const genesysCommand = useRef(null);
  const { isMobile } = getDevice();

  if(!isActive || isMobile) {
    return null;
  }

  const shouldShowButton = () => {
    if (!window?.audaxChat) {
      return false;
    }

    const { enabled, openingHours } = window.audaxChat || {};

    if (!openingHours) {
      return false;  // Don't show the button until openingHours is available
    }

    const { week = {}, saturday = {} } = openingHours;
    let withinOpeningHours = false;
    const now = new Date();
    const dayOfWeek = now.getDay(); // Sunday = 0, Saturday = 6
    const hourOfDay = now.getHours(); // 24-hour notation
    const isWeekday = (dayOfWeek >= 1 && dayOfWeek <= 5); // Monday to Friday
    const weekdayAndOnTime = (isWeekday && (hourOfDay >= week.start && hourOfDay < week.end));
    const isSaturday = (now.getDay() === 6);
    const saturdayAndOnTime = (isSaturday && (hourOfDay >= saturday.start && hourOfDay < saturday.end));

    if (weekdayAndOnTime || saturdayAndOnTime) {
      withinOpeningHours = true;
    }

    return enabled && withinOpeningHours;
  };

  useEffect(() => {
    const checkOpeningHours = () => {
      if (window?.audaxChat?.openingHours) {
        const available = shouldShowButton();
        setIsChatAvailable(available);
      } else {
        setIsChatAvailable(false);
      }
    };

    // Check availability every second until `openingHours` is loaded
    const interval = setInterval(() => {
      checkOpeningHours();
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (typeof window === 'undefined'){
      return null;
    };

    setFormData(prevState => ({ ...prevState, url: window.location.href }));

    if (!window.Genesys) {
      const deploymentId = PRODUCTION ? deploymentKey.live : deploymentKey.test;
      window.Genesys = (...args) => (window.Genesys.q = window.Genesys.q || []).push(args);
      window.Genesys.c = { environment, deploymentId };

      const script = document.createElement('script');
      script.src = 'https://apps.mypurecloud.de/genesys-bootstrap/genesys.min.js';
      script.async = true;
      script.charset = 'utf-8';
      script.onload = () => {
        genesysCommand.current = window.Genesys;

        genesysCommand.current('subscribe', 'Messenger.closed', () => {
          setIsChatOpen(false);
        });
      };
      script.onerror = () => console.error('Failed to load Genesys script.');
      document.head.appendChild(script);
    } else {
      genesysCommand.current = window.Genesys;
      genesysCommand.current('subscribe', 'Messenger.closed', () => {
        setIsChatOpen(false);
      });
    }
  }, []);

  const openErrorModal = () => {
    store.modal.openModal('popup', 'center', {
      title: t('chat.widget.error.title'),
      component: (
        <div>
          <Text html>chat.widget.error.msg</Text>
          <br/><br/>
          <Button variant="primary" size="large" onClick={() => window.location.href = t('chat.widget.error.button.url')}>
            <Text>chat.widget.error.button.title</Text>
          </Button>
        </div>
      ),
    });
  };

  const handleStartChat = () => {
    if (!genesysCommand.current) {
      console.error('Genesys is not initialized.');
      openErrorModal();
      return null;
    }

    try {
      genesysCommand.current('command', 'Database.set', {
        messaging: { customAttributes: formData },
      });
      genesysCommand.current('command', 'Messenger.open');
      genesysCommand.current('subscribe', 'Messenger.ready', () => {
        setIsChatOpen(true);
      });
      setIsFormVisible(false);
    } catch (error) {
      console.error('Failed to open Genesys Messenger:', error);
      openErrorModal();
    }
  };

  const formConfig = [
    {
      type: 'fields',
      children: [
        {
          name: 'name',
          label: t('chat.widget.prechat.input.name.label'),
          className: 'col-md-12 input-container',
          stacked: true,
          htmlAttributes: {
            value: formData.name,
          },
        },
        {
          name: 'email',
          label: t('chat.widget.prechat.input.email.label'),
          className: 'col-md-12 input-container',
          stacked: true,
          htmlAttributes: {
            value: formData.email,
          },
        },
        {
          name: 'question',
          label: t('chat.widget.prechat.input.question.label'),
          className: 'col-md-12 input-container',
          stacked: true,
          htmlAttributes: {
            type: 'textarea',
            value: formData.question,
          },
        },
      ],
    },
  ];

  const updateFormDataState = (name, value) => {
    setFormData(prevState => ({ ...prevState, [name]: value }));
  };

  return (
    <div className="chat-widget-container">
      {isFormVisible ? (
        <div className="pre-chat-form">
          <div className="form-header">
            <h2><Text>chat.widget.prechat.title</Text></h2>
            <div className="close-form">
              <Icon name="Xmark" onClick={() => setIsFormVisible(false)} />
            </div>
          </div>
          <p><Text>chat.widget.prechat.description</Text></p>
          <GenericForm
            name="chatForm"
            formConfig={formConfig}
            formData={formData}
            updateFormDataState={updateFormDataState}
            formErrors={{}}
          />
          <Space size={2} />
          <Button onClick={handleStartChat}><Text>chat.widget.prechat.startchat</Text></Button>
        </div>
      ) : (
        isChatAvailable && !isChatOpen &&(
          <Button iconLeft={<Icon name="ChatLines" />} id="webMessengerButton" onClick={() => setIsFormVisible(true)} />
        )
      )}
    </div>
  );
};

ChatWidget.propTypes = {
  store: PropTypes.object,
};

export default ChatWidget;
