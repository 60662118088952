import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

const Space = (props) => {
  const { vertical, size } = props;
  return <div className={`empty-space-${vertical ? 'x' : 'y'}-${size}`}/>;
};

Space.propTypes = {
  vertical: PropTypes.bool,
  size: PropTypes.number,
};

export default Space;
